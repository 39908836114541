import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const HeaderText = styled.h1`
  font-size: 30px;
  color: white;
`;

const TermsMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: white;
`;

const Roadmap = () => (
  <Layout>
    <Seo title="Playground Roadmap + Vision" />
    <TermsMain>
        <HeaderText>Vision for Playground</HeaderText>
        <Text>Playground was founded to support creators within the Solana ecosystem.</Text>
        <Text>After releasing our first collection, Waves, our long term vision is to build a community owned & community governed platform, by and for art focused creators.</Text>
        <Text>Our aim is to work with as many projects within the Solana ecosystem as possible who are building out these various layers (DAOs, memberships, analytics, fractionalization, and more) and when appropriate build & open source certain core components for other projects to use within the Solana ecosystem. Playground is an experiment to see how far we can decentralize the whole process: curation, development, funding, collecting, etc.</Text>
        <Text>The Waves collection will be a foundational part of the future Playground ecosystem, and thus Wave holders will both receive special privileges for future drops as well as being the core backbone to govern the Playground platform itself.</Text>
        <Text>We're committed to shipping and iterating quickly and will keep the community in the loop as we build through our roadmap below. Let’s have fun building Playground together. ◎</Text>
        <HeaderText>Playground Roadmap</HeaderText>
        <Text>
            This is the live, most up-to-date roadmap that the Playground team is using to manage development and new initiatives.
        </Text>
        <iframe src="https://view.monday.com/embed/1758295323-b0c5c7521aad9976d59834f033313d6d" width="1270" height="800" style={{ border: "0", boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)"}}></iframe>
    </TermsMain>
  </Layout>
)

export default Roadmap
